<template>
  <card
    :content="content"
    :schema="getSchemaRearer"
    :form-data="getDataRearer"
    :editable="getEditableRearer"
    @update-form="updateRearer"
    @submitAction="addRearer"
  />
</template>
<script>
/* eslint-disable no-console */
import Card from "../components/Card";
import confRearer from "../conf/confRearer";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Rearer",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confRearer.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("rearer/loadRearer");
  },
  computed: {
    ...mapGetters("rearer", [
      "getDataRearer",
      "getSchemaRearer",
      "getEditableRearer"
    ])
  },
  methods: {
    ...mapActions("rearer", ["updateRearer", "addRearer", "loadRearer"])
    /*checkSizes() {
      if (document.getElementById("content")) {
        return document.getElementById("content").clientWidth;
      } else if (document.getElementById("submitForm")) {
        return document.getElementById("submitForm").clientWidth;
      } else return window.screen.availWidth;
    }*/
  }

  /*mounted() {
    let hwidth = this.checkSizes();
    if (hwidth < 820) this.$store.state.rearer.schema.rStables.dense = true;
  }*/
};
</script>

<style scoped></style>
